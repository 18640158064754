<template>
  <div class="form-wrap">
    <div class="form-item">
      <div class="form-label">
        大小
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.width" :min="10" :max="300">
        </el-slider>
      </div>
    </div>

    <!-- <div class="form-item">
      <div class="form-label">
        旋转
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.rotate" :min="0" :max="360">
        </el-slider>
      </div>
    </div> -->

    <div class="form-item">
      <div class="form-label">
        横向倾斜
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.skewX" :min="-90" :max="90">
        </el-slider>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        纵向倾斜
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.skewY" :min="-90" :max="90">
        </el-slider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customModel: {
      type: Object,
      default: () => {}
    },
    customActiveIndex: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="less" scoped></style>
