<template>
  <div class="form-wrap">
    <div class="form-item">
      <div class="form-label">
        内容
      </div>
      <div class="form-content">
        <vs-input
          v-model="customModel.value"
          placeholder="请输入自定义文字"
        ></vs-input>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        字体
      </div>
      <div class="form-content align-items-center">
        <vs-select v-model="customModel.fontFamily">
          <vs-option
            v-for="(item, index) in fontFamilyList"
            :key="index"
            :label="item"
            :value="item"
          >
            {{ item }}
          </vs-option>
        </vs-select>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        加粗
      </div>
      <div class="form-content align-items-center">
        <vs-switch style="width: 48px !important;" v-model="customModel.bold" />
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        颜色
      </div>
      <div class="form-content">
        <el-color-picker v-model="customModel.color"></el-color-picker>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        字号
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.fontSize" :min="1" :max="100">
        </el-slider>
      </div>
    </div>

    <!-- <div class="form-item">
      <div class="form-label">
        旋转
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.rotate" :min="0" :max="360">
        </el-slider>
      </div>
    </div> -->

    <div class="form-item">
      <div class="form-label">
        横向倾斜
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.skewX" :min="-90" :max="90">
        </el-slider>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        纵向倾斜
      </div>
      <div class="form-content">
        <el-slider v-model="customModel.skewY" :min="-90" :max="90">
        </el-slider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customModel: {
      type: Object,
      default: () => {},
    },
    customActiveIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    'customModel.fontFamily'() {
      if (localStorage.FONT_FAMILY_CACHE) return;
      this.$alert(
        '由于字体文件比较大，第一次加载可能比较慢，请耐心等待!',
        '提示',
        {
          confirmButtonText: '我知道了',
          callback: () => {
            localStorage.FONT_FAMILY_CACHE = true;
          },
        }
      );
    },
  },
  data() {
    return {
      fontFamilyList: [
        '微软雅黑',
        '正文宋楷',
        '经典雅黑',
        '手刻宋',
        '行云飞白体',
        '瘦金体',
        '江南手书',
        '云溪锦书',
        '趣味体',
        '彩虹高光体',
      ],
    };
  },
};
</script>

<style lang="less" scoped></style>
