<template>
  <vs-dialog width="300px" not-center v-model="visible">
    <template #header>
      <h4 class="dialog-title">
        请输入自定义文字
      </h4>
    </template>

    <div class="con-content">
      <vs-input
        v-model="customModel.value"
        autocomplete="off"
        @keyup.enter="confirmCustomText"
        placeholder="请输入自定义文字"
      ></vs-input>
    </div>

    <template #footer>
      <div class="con-footer">
        <vs-button @click="visible = false" dark transparent>
          取消
        </vs-button>
        <vs-button @click="confirmCustomText" transparent>
          确认
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val); // visible 改变的时候通知父组件
      },
    },
  },
  watch: {
    visible() {
      this.customModel.value = '';
    },
  },
  data() {
    return {
      customModel: {
        type: 'text',
        value: '',
        positionX: '50%',
        positionY: '50%',
        fontFamily: '微软雅黑',
        bold: false,
        fontSize: 12,
        skewX: 0,
        skewY: 0,
        color: '#333',
        rotate: 0,
        zIndex: 0,
      },
    };
  },
  methods: {
    // 确认自定义文字弹窗
    confirmCustomText() {
      if (this.customModel.value.trim() === '') return;
      const customModel = _.cloneDeep(this.customModel);
      this.$emit('confirm', customModel);
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-title {
  margin: 0;
  font-weight: 400;
  padding: 10px 10px 0;
  font-weight: bold;
}

.vs-dialog__content {
  padding: 10px 16px;
  width: 100%;
  position: relative;
  border-radius: inherit;
}

.con-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
