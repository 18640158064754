<template>
  <div class="page-footer">
    <div class="copyright">
      <div class="logo-wrap">
        <img width="100" src="@/assets/images/logo.png" alt="" />
        <span class="web-font">可美口罩定制设计平台</span>
      </div>
      <p>Copyright © {{ currentYear }} 苏ICP备2021000430号-1</p>
      <p>
        <img
          style="vertical-align: middle; margin-right: 5px;"
          height="16"
          src="@/assets/images/beian.png"
          alt=""
        />
        <span>苏公网安备 32059002003396号</span>
      </p>
      <p>
        <i class="el-icon-phone-outline">&emsp;联系电话 18761988163（微信）</i>
      </p>
    </div>
    <div class="companyinfo">
      可美公司是一家坐落在江苏常熟有着30多年发展历史的生产型企业。近年来，可美公司加大研发投入，相继开发了一次性平面口罩、KN95等防疫物资。同时，在工艺上大胆创新，先后研发出了包括喷印口罩、烫印口罩、满版定位口罩等一系列深受客户喜爱的产品。
    </div>
    <div class="sotreinfo">
      <div>
        <p>淘宝旺铺</p>
        <img height="100" src="@/assets/images/tb.png" alt="" />
      </div>
      <div>
        <p>阿里巴巴旺铺</p>
        <img height="100" src="@/assets/images/alibaba.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fadeIn: false
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.fadeIn = true;
  }
};
</script>

<style lang="less" scoped>
.page-footer {
  padding: 30px 50px;
  box-sizing: border-box;
  background: #1f2329;
  color: #d2d3d4;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .logo-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    span {
      display: inline-block;
      background-color: #fff;
      color: #1f2329;
      padding: 6px 9px 8px 9px;
      line-height: 1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      margin-left: 12px;
      transform: translateY(-12px);
      font-family: 手刻宋;
    }
  }
  .copyright {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #7b7e81;
    p {
      margin: 4px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .companyinfo {
    max-width: 700px;
    line-height: 1.8;
    font-size: 14px;
    text-indent: 28px;
  }
  .sotreinfo {
    min-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: 经典雅黑;
    & > div:last-child {
      margin-left: 30px;
    }
    img {
      border-radius: 4px;
    }
  }
}
@media screen and (max-width: 768px) {
  .page-footer {
    flex-direction: column;
    padding: 10px;
    & > div {
      margin: 20px;
    }
  }
}
</style>
