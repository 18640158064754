const IMG_URL = 'http://39.99.237.105/images';

export default [
  {
    name: '平面',
    colors: [
      {
        name: '白',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/白3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/白1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/白2.png',
          },
        ],
      },
      {
        name: '橙',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/橙3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/橙1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/橙2.png',
          },
        ],
      },
      {
        name: '黑',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/黑3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/黑1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/黑2.png',
          },
        ],
      },
      {
        name: '红',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/红3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/红1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/红2.png',
          },
        ],
      },
      {
        name: '黄',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/黄3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/黄1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/黄2.png',
          },
        ],
      },
      {
        name: '灰',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/灰3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/灰1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/灰2.png',
          },
        ],
      },
      {
        name: '蓝',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/蓝3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/蓝1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/蓝2.png',
          },
        ],
      },
      {
        name: '绿',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/绿3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/绿1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/绿2.png',
          },
        ],
      },
      {
        name: '浅粉',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/浅粉3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/浅粉1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/浅粉2.png',
          },
        ],
      },
      {
        name: '浅绿',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/浅绿3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/浅绿1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/浅绿2.png',
          },
        ],
      },
      {
        name: '深粉',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/深粉3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/深粉1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/深粉2.png',
          },
        ],
      },
      {
        name: '紫',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/平面/紫3.png',
          },
          {
            name: '中',
            url: IMG_URL + '/平面/紫1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/平面/紫2.png',
          },
        ],
      },
    ],
  },
  {
    name: '韩式',
    colors: [
      {
        name: '白',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/韩式/白正.png',
          },
          {
            name: '中',
            url: IMG_URL + '/韩式/白.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/韩式/白侧.png',
          },
        ],
      },
      {
        name: '黑',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/韩式/黑正.png',
          },
          {
            name: '中',
            url: IMG_URL + '/韩式/黑.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/韩式/黑侧.png',
          },
        ],
      },
    ],
  },
  {
    name: 'KN95',
    colors: [
      {
        name: '白色',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/KN95/KN95正.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/KN95/KN95侧.png',
          },
          {
            name: '右',
            url: IMG_URL + '/KN95/右.png',
          },
          {
            name: '左',
            url: IMG_URL + '/KN95/左.png',
          },
        ],
      },
    ],
  },
  {
    name: '海绵',
    colors: [
      {
        name: '白',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/海绵/白1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/海绵/白2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/海绵/白3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/海绵/白4.png',
          },
        ],
      },
      {
        name: '粉',
        directions: [
          {
            name: '正面',
            url: IMG_URL + '/海绵/粉1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/海绵/粉2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/海绵/粉3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/海绵/粉4.png',
          },
        ],
      },
      {
        name: '黑',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/海绵/黑1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/海绵/黑2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/海绵/黑3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/海绵/黑4.png',
          },
        ],
      },
      {
        name: '黄',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/海绵/黄-1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/海绵/黄2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/海绵/黄3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/海绵/黄4.png',
          },
        ],
      },
      {
        name: '蓝',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/海绵/蓝1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/海绵/蓝2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/海绵/蓝3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/海绵/蓝4.png',
          },
        ],
      },
    ],
  },
  {
    name: '丝柔',
    colors: [
      {
        name: '白',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/白1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/白2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/白3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/白4.png',
          },
        ],
      },
      {
        name: '橙',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/橙1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/橙2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/橙3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/橙4.png',
          },
        ],
      },
      {
        name: '黑',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/黑1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/黑2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/黑3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/黑4.png',
          },
        ],
      },
      {
        name: '浅粉',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/浅粉1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/浅粉2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/浅粉3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/浅粉4.png',
          },
        ],
      },
      {
        name: '浅灰',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/浅灰1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/浅灰2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/浅灰3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/浅灰4.png',
          },
        ],
      },
      {
        name: '深粉',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/深粉1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/深粉2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/深粉3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/深粉4.png',
          },
        ],
      },
      {
        name: '深灰',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/丝柔/深灰1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/丝柔/深灰2.png',
          },
          {
            name: '右',
            url: IMG_URL + '/丝柔/深灰3.png',
          },
          {
            name: '左',
            url: IMG_URL + '/丝柔/深灰4.png',
          },
        ],
      },
    ],
  },

  {
    name: '棉布',
    colors: [
      {
        name: '黑',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/棉布/黑1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/棉布/黑2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/棉布/黑3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/棉布/黑4.png',
          },
        ],
      },
      {
        name: '红',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/棉布/红1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/棉布/红2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/棉布/红3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/棉布/红4.png',
          },
        ],
      },
      {
        name: '蓝',
        directions: [
          {
            name: '中',
            url: IMG_URL + '/棉布/蓝1.png',
          },
          {
            name: '立体',
            url: IMG_URL + '/棉布/蓝2.png',
          },
          {
            name: '左',
            url: IMG_URL + '/棉布/蓝3.png',
          },
          {
            name: '右',
            url: IMG_URL + '/棉布/蓝4.png',
          },
        ],
      },
    ],
  },
];
