<template>
  <div>
    <div class="diy-wrap">
      <div class="top-filter-wrap">
        <div class="top-filter-content">
          <div class="filter-btn-wrap">
            <div class="filter-btn-title">类型：</div>
            <div class="filter-btn-content">
              <div
                @click="
                  selectedDirection = 0;
                  selectedColor = 0;
                  selectedType = tIndex;
                "
                class="filter-btn"
                :class="{ 'active-btn': selectedType === tIndex }"
                v-for="(tItem, tIndex) in goodsList"
                :key="tIndex"
              >
                {{ tItem.name }}
              </div>
            </div>
          </div>

          <div class="filter-btn-wrap">
            <div class="filter-btn-title">颜色：</div>
            <div class="filter-btn-content">
              <div
                @click="
                  selectedDirection = 0;
                  selectedColor = cIndex;
                "
                class="filter-btn"
                :class="{ 'active-btn': selectedColor === cIndex }"
                v-for="(cItem, cIndex) in goodsList[selectedType].colors"
                :key="cIndex"
              >
                {{ cItem.name }}
              </div>
            </div>
          </div>

          <div class="filter-btn-wrap">
            <div class="filter-btn-title">角度：</div>
            <div class="filter-btn-content">
              <div
                @click="selectedDirection = dIndex"
                class="filter-btn"
                :class="{ 'active-btn': selectedDirection === dIndex }"
                v-for="(dItem, dIndex) in goodsList[selectedType].colors[
                  selectedColor
                ].directions"
                :key="dIndex"
              >
                <img v-lazy="dItem.url" :key="dItem.url" alt="" />
                {{ dItem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <!-- 左边图片部分 -->
        <div class="left-img-wrap">
          <div id="canvasWrap" class="canvas-wrap">
            <img v-lazy="materialImgUrl" :key="materialImgUrl" alt="" />
            <div v-if="isShowWatermark" class="watermark web-font">
              可美口罩
            </div>
            <span
              class="web-font move-text"
              :style="{
                top: item.positionY,
                left: item.positionX,
                fontFamily: item.fontFamily,
                color: item.color,
                transform: `rotate(-${item.rotate}deg) skew(-${item.skewX +
                  180}deg, -${item.skewY + 180}deg) scale(${fontSizeToScale})`,
                fontWeight: item.bold ? 'bold' : 'normal',
              }"
              @mousedown="move($event, item, index)"
              v-for="(item, index) in customList"
              :key="'text_' + index"
              >{{ item.value }}</span
            >
            <img
              class="move-img"
              :style="{
                top: item.positionY,
                left: item.positionX,
                width: `${item.width}px`,
                transform: `rotate(-${item.rotate}deg) skew(-${item.skewX +
                  180}deg, -${item.skewY + 180}deg)`,
              }"
              @mousedown="move($event, item, index)"
              v-for="(item, index) in customList"
              :key="'img_' + index"
              :src="item.url"
              alt=""
            />
          </div>
        </div>

        <!-- 右边筛选条件和操作 -->
        <div class="right-filter-wrap">
          <div v-if="currentAuthInfo" class="filter-item">
            <div class="filter-title">
              <span>授权码 {{ currentAuthInfo.authCode }}</span>
              <el-button
                @click="handleAuth"
                type="text"
                style="padding: 0;"
                icon="el-icon-refresh"
                >重新授权</el-button
              >
            </div>
            <div style="text-align: center; padding: 6px;">
              剩余点数：{{ currentAuthInfo.inventory }}
            </div>
          </div>

          <!-- 按钮开始 -->
          <div class="filter-item">
            <div class="filter-title">
              <span>操作</span>
              <a href="http://www.picup.shop/posterEditor.html" target="_blank">
                <el-button type="text" style="padding: 0;" icon="el-icon-link"
                  >抠图网站</el-button
                >
              </a>
            </div>
            <div class="space-between">
              <vs-button color="#409eff" @click="addCustomText"
                >添加文字</vs-button
              >

              <el-upload
                class="upload-demo"
                accept=".jpg,.jpeg,.png,.JPG,.JEPG,.PNG"
                :http-request="httpRequest"
                action="#"
              >
                <vs-button color="#409eff">上传PNG格式图片</vs-button>
              </el-upload>
              <vs-button color="#1243cd" v-if="materialImgUrl" @click="saveImg">
                保存图片去除水印
              </vs-button>
            </div>
          </div>

          <!-- 编辑文字样式开始 -->
          <div
            class="filter-item"
            v-if="
              Object.keys(customModel).length > 0 && customModel.type === 'text'
            "
          >
            <div class="filter-title">编辑文字</div>
            <edit-custom-text
              :custom-model="customModel"
              :custom-active-index="customActiveIndex"
            ></edit-custom-text>

            <div class="all-center" style="margin-top: 15px;">
              <vs-button
                @click="
                  customModel = {};
                  customList.splice(customActiveIndex, 1);
                "
                color="#f56c6c"
                >删除文字</vs-button
              >
            </div>
          </div>

          <!-- 编辑图片开始 -->
          <div
            class="filter-item"
            v-if="
              Object.keys(customModel).length > 0 && customModel.type === 'img'
            "
          >
            <div class="filter-title">编辑图片</div>
            <edit-custom-img
              :custom-model="customModel"
              :custom-active-index="customActiveIndex"
            ></edit-custom-img>

            <div class="all-center" style="margin-top: 15px;">
              <vs-button
                @click="
                  customModel = {};
                  customList.splice(customActiveIndex, 1);
                "
                color="#f56c6c"
                >删除图片</vs-button
              >
            </div>
          </div>
        </div>
      </div>

      <!-- 弹窗部分 -->
      <add-text-dialog
        :show.sync="customTextDialog"
        @confirm="confirmAddText"
      ></add-text-dialog>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import goodsList from '@/assets/js/goodsMap';
import html2canvas from 'html2canvas';
import AddTextDialog from './components/AddTextDialog';
import EditCustomText from './components/EditCustomText';
import EditCustomImg from './components/EditCustomImg';
import saveImg from '@/utils/saveImg';
import { getAuthCode, consumeCode } from '@/api/authCode';
import PageFooter from '@/components/PageFooter';

export default {
  data() {
    return {
      goodsList, // 商品列表
      selectedType: 0, // 选中的类型 索引
      selectedColor: 0, // 选中的颜色 索引
      selectedDirection: 0, // 选中的方向
      customTextDialog: false,
      customList: [],
      customModel: {}, // 编辑的对象
      customActiveIndex: 0, // 编辑的索引
      currentAuthInfo: null, // 当前授权信息
      isShowWatermark: true, // 是否展示水印
    };
  },
  computed: {
    materialImgUrl() {
      return this.goodsList[this.selectedType].colors[this.selectedColor]
        .directions[this.selectedDirection].url;
    },
    fontSizeToScale() {
      return this.customModel.fontSize / 12;
    },
  },
  components: {
    AddTextDialog,
    EditCustomText,
    EditCustomImg,
    PageFooter,
  },
  mounted() {
    // TODO: 暂时写死授权码
    localStorage.LOGIN_AUTH_CODE = '996236';

    if (!localStorage.LOGIN_AUTH_CODE) {
      this.handleAuth();
    } else {
      this.getAuthCode(localStorage.LOGIN_AUTH_CODE, 'mounted');
    }
  },
  methods: {
    // 新增字体按钮
    addCustomText() {
      if (!this.currentAuthInfo) return this.handleAuth();
      this.customTextDialog = true;
    },
    // 授权
    handleAuth() {
      const self = this;
      self
        .$prompt('请输入授权码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\d{6}$/,
          inputErrorMessage: '请输入正确的授权码',
        })
        .then(async ({ value }) => {
          if (!value) return self.$message.error('请输入授权码');
          self.getAuthCode(value);
        })
        .catch(() => {});
    },
    // 消耗点数
    consumeCode() {
      return new Promise((resolve, reject) => {
        const self = this;
        const authCode = self.currentAuthInfo.authCode;
        consumeCode({
          authCode,
        })
          .then(async () => {
            await self.getAuthCode(authCode);
            resolve(true);
          })
          .catch((err) => {
            self.$message.error(err);
            reject(err);
          });
      });
    },
    // 获取某个授权码
    getAuthCode(authCode, type) {
      const self = this;
      getAuthCode({
        authCode,
      })
        .then((res) => {
          if (res.authCode) {
            self.currentAuthInfo = res;
            localStorage.LOGIN_AUTH_CODE = res.authCode;
          }
        })
        .catch((err) => {
          self.$message.error(err);
          if (type === 'mounted') {
            localStorage.removeItem('LOGIN_AUTH_CODE');
            self.handleAuth();
          }
        });
    },
    // 保存图片
    saveImg() {
      const self = this;
      if (!self.currentAuthInfo) return self.handleAuth();
      const loading = self.$vs.loading();
      self
        .consumeCode()
        .then(() => {
          self.isShowWatermark = false;
          self.customModel = {};
          window.scroll(0, 0);
          setTimeout(() => {
            html2canvas(document.getElementById('canvasWrap'), {
              useCORS: true,
              logging: true,
              // backgroundColor: null,
            }).then((canvas) => {
              saveImg(canvas, '下载图片');
              self.isShowWatermark = true;
              loading.close();
            });
          }, 300);
        })
        .catch(() => {
          loading.close();
        });
    },
    // 移动
    move(e, item, index) {
      let self = this;
      self.editTextOrImg(item, index);
      this.pauseEvent(e);
      let odiv = e.target; //获取目标元素
      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        if (item.type === 'text') {
          self.$set(self.customList[index], 'positionX', `${left}px`);
          self.$set(self.customList[index], 'positionY', `${top}px`);
        } else {
          self.$set(self.customList[index], 'positionX', `${left}px`);
          self.$set(self.customList[index], 'positionY', `${top}px`);
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    pauseEvent(e) {
      //已做兼容性处理
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    },
    // 新增自定义文字弹窗
    confirmAddText(value) {
      this.customList.push(value);
      const lastIndex = this.customList.length - 1;
      this.editTextOrImg(this.customList[lastIndex], lastIndex);
    },
    // 编辑文字/图片
    editTextOrImg(item, index) {
      if (item.type === 'text') {
        this.customModel = item;
        this.customActiveIndex = index;
      } else {
        this.customModel = item;
        this.customActiveIndex = index;
      }
    },
    httpRequest(data) {
      let self = this;
      if (!self.currentAuthInfo) return self.handleAuth();
      let rd = new FileReader();
      let file = data.file;
      rd.readAsDataURL(file);
      rd.onloadend = function(e) {
        const customIng = {
          type: 'img',
          url: e.target.result,
          positionX: '50%',
          positionY: '50%',
          width: 150,
          skewX: 0,
          skewY: 0,
          rotate: 0,
          zIndex: 0,
        };
        self.customList.push(customIng);
        const lastIndex = self.customList.length - 1;
        self.editTextOrImg(self.customList[lastIndex], lastIndex);
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list {
  display: none;
}

@mainContainerWidth: 1150px;

.all-center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.diy-wrap {
  user-select: none;
  padding-bottom: 20px;
  min-height: calc(100vh - 120px);
  box-sizing: border-box;

  .top-filter-wrap {
    // box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: #fff;
    .top-filter-content {
      max-width: @mainContainerWidth;
      margin: auto;
      padding: 10px 0;
      box-sizing: border-box;
      .filter-btn-wrap {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &:last-child .filter-btn-content {
          border-bottom: 0;
        }
        .filter-btn-title {
          width: 60px;
          font-size: 14px;
          margin-right: 30px;
          height: 59px;
          line-height: 59px;
        }
        .filter-btn-content {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-bottom: 1px dashed #eee;
          .filter-btn {
            border: 1px solid #dcdfe6;
            background-color: #fff;
            color: #606266;
            font-size: 12px;
            padding: 0 12px;
            min-height: 38px;
            box-sizing: border-box;
            border-radius: 3px;
            margin: 10px 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // transition: all 0.17s linear;
            &:hover,
            &.active-btn {
              cursor: pointer;
              color: #1243cd;
              border-color: #a0b4eb;
            }
            img {
              height: 25px;
              min-width: 25px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .main-content {
    max-width: @mainContainerWidth;
    margin: auto;
    display: flex;
    flex-direction: row;

    .left-img-wrap {
      flex: 5;
      padding-right: 20px;
      box-sizing: border-box;
      .canvas-wrap {
        position: relative;
        overflow: hidden;
        // border: 1px solid #eee;
        border-radius: 10px;
        background-color: #fff;
        // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        img {
          width: 100%;
        }
        .watermark {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 38px;
          padding: 15px;
          font-family: 手刻宋;
          color: rgba(199, 199, 199, 0.6);
          transform: translate(-50%, -50%);
        }
        .move-text {
          position: absolute;
          font-size: 12px;
          transition: text-shadow ease-in 0.2s;
          &:hover {
            cursor: pointer;
            text-shadow: 0 3px 11px rgba(0, 0, 0, 0.5);
          }
        }
        .move-img {
          position: absolute;
          width: 150px;
          transition: box-shadow ease-in 0.2s;
          user-select: none;
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
            box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }
        }
      }
      .save-btn-wrap {
        .all-center;
        margin-top: 20px;
      }
    }

    .right-filter-wrap {
      flex: 3;
      min-width: 380px;
      box-sizing: border-box;
    }
  }

  .filter-item {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    &:nth-child(1) {
      margin-top: 0;
    }

    .filter-title {
      font-weight: bold;
      font-size: 17px;
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      line-height: 1;
      &:before {
        position: absolute;
        content: '';
        width: 5px;
        background-color: #1243cd;
        height: 26px;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top-filter-content {
    padding-left: 10px !important;
    box-sizing: border-box;
  }
  .main-content {
    flex-direction: column !important;
    padding: 0 20px !important;
    .left-img-wrap {
      margin-bottom: 20px;
      padding-right: 0 !important;
    }
  }
}
</style>
