import Canvas2Image from "@/utils/canvas2image";

export default function saveImg(canvas, name) {
  //判断是否是IE
  var fileName = `${name}.png`;
  if (window.navigator.msSaveBlob) {
    //支持IE10，IE11 ,Edage
    var blob = canvas.msToBlob();
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    try {
      //下载图片,Goggle浏览器，火狐浏览器
      var dataImg = new Image();
      dataImg.src = canvas.toDataURL("image/png");
      var alink = document.createElement("a");
      alink.href = dataImg.src;
      alink.download = fileName;
      alink.click();
    } catch {
      Canvas2Image.saveAsPNG(canvas);
    }
  }
}
