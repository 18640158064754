var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"diy-wrap"},[_c('div',{staticClass:"top-filter-wrap"},[_c('div',{staticClass:"top-filter-content"},[_c('div',{staticClass:"filter-btn-wrap"},[_c('div',{staticClass:"filter-btn-title"},[_vm._v("类型：")]),_c('div',{staticClass:"filter-btn-content"},_vm._l((_vm.goodsList),function(tItem,tIndex){return _c('div',{key:tIndex,staticClass:"filter-btn",class:{ 'active-btn': _vm.selectedType === tIndex },on:{"click":function($event){_vm.selectedDirection = 0;
                _vm.selectedColor = 0;
                _vm.selectedType = tIndex;}}},[_vm._v(" "+_vm._s(tItem.name)+" ")])}),0)]),_c('div',{staticClass:"filter-btn-wrap"},[_c('div',{staticClass:"filter-btn-title"},[_vm._v("颜色：")]),_c('div',{staticClass:"filter-btn-content"},_vm._l((_vm.goodsList[_vm.selectedType].colors),function(cItem,cIndex){return _c('div',{key:cIndex,staticClass:"filter-btn",class:{ 'active-btn': _vm.selectedColor === cIndex },on:{"click":function($event){_vm.selectedDirection = 0;
                _vm.selectedColor = cIndex;}}},[_vm._v(" "+_vm._s(cItem.name)+" ")])}),0)]),_c('div',{staticClass:"filter-btn-wrap"},[_c('div',{staticClass:"filter-btn-title"},[_vm._v("角度：")]),_c('div',{staticClass:"filter-btn-content"},_vm._l((_vm.goodsList[_vm.selectedType].colors[
                _vm.selectedColor
              ].directions),function(dItem,dIndex){return _c('div',{key:dIndex,staticClass:"filter-btn",class:{ 'active-btn': _vm.selectedDirection === dIndex },on:{"click":function($event){_vm.selectedDirection = dIndex}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(dItem.url),expression:"dItem.url"}],key:dItem.url,attrs:{"alt":""}}),_vm._v(" "+_vm._s(dItem.name)+" ")])}),0)])])]),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"left-img-wrap"},[_c('div',{staticClass:"canvas-wrap",attrs:{"id":"canvasWrap"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.materialImgUrl),expression:"materialImgUrl"}],key:_vm.materialImgUrl,attrs:{"alt":""}}),(_vm.isShowWatermark)?_c('div',{staticClass:"watermark web-font"},[_vm._v(" 可美口罩 ")]):_vm._e(),_vm._l((_vm.customList),function(item,index){return _c('span',{key:'text_' + index,staticClass:"web-font move-text",style:({
              top: item.positionY,
              left: item.positionX,
              fontFamily: item.fontFamily,
              color: item.color,
              transform: ("rotate(-" + (item.rotate) + "deg) skew(-" + (item.skewX +
                180) + "deg, -" + (item.skewY + 180) + "deg) scale(" + _vm.fontSizeToScale + ")"),
              fontWeight: item.bold ? 'bold' : 'normal',
            }),on:{"mousedown":function($event){return _vm.move($event, item, index)}}},[_vm._v(_vm._s(item.value))])}),_vm._l((_vm.customList),function(item,index){return _c('img',{key:'img_' + index,staticClass:"move-img",style:({
              top: item.positionY,
              left: item.positionX,
              width: ((item.width) + "px"),
              transform: ("rotate(-" + (item.rotate) + "deg) skew(-" + (item.skewX +
                180) + "deg, -" + (item.skewY + 180) + "deg)"),
            }),attrs:{"src":item.url,"alt":""},on:{"mousedown":function($event){return _vm.move($event, item, index)}}})})],2)]),_c('div',{staticClass:"right-filter-wrap"},[(_vm.currentAuthInfo)?_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"filter-title"},[_c('span',[_vm._v("授权码 "+_vm._s(_vm.currentAuthInfo.authCode))]),_c('el-button',{staticStyle:{"padding":"0"},attrs:{"type":"text","icon":"el-icon-refresh"},on:{"click":_vm.handleAuth}},[_vm._v("重新授权")])],1),_c('div',{staticStyle:{"text-align":"center","padding":"6px"}},[_vm._v(" 剩余点数："+_vm._s(_vm.currentAuthInfo.inventory)+" ")])]):_vm._e(),_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"filter-title"},[_c('span',[_vm._v("操作")]),_c('a',{attrs:{"href":"http://www.picup.shop/posterEditor.html","target":"_blank"}},[_c('el-button',{staticStyle:{"padding":"0"},attrs:{"type":"text","icon":"el-icon-link"}},[_vm._v("抠图网站")])],1)]),_c('div',{staticClass:"space-between"},[_c('vs-button',{attrs:{"color":"#409eff"},on:{"click":_vm.addCustomText}},[_vm._v("添加文字")]),_c('el-upload',{staticClass:"upload-demo",attrs:{"accept":".jpg,.jpeg,.png,.JPG,.JEPG,.PNG","http-request":_vm.httpRequest,"action":"#"}},[_c('vs-button',{attrs:{"color":"#409eff"}},[_vm._v("上传PNG格式图片")])],1),(_vm.materialImgUrl)?_c('vs-button',{attrs:{"color":"#1243cd"},on:{"click":_vm.saveImg}},[_vm._v(" 保存图片去除水印 ")]):_vm._e()],1)]),(
            Object.keys(_vm.customModel).length > 0 && _vm.customModel.type === 'text'
          )?_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"filter-title"},[_vm._v("编辑文字")]),_c('edit-custom-text',{attrs:{"custom-model":_vm.customModel,"custom-active-index":_vm.customActiveIndex}}),_c('div',{staticClass:"all-center",staticStyle:{"margin-top":"15px"}},[_c('vs-button',{attrs:{"color":"#f56c6c"},on:{"click":function($event){_vm.customModel = {};
                _vm.customList.splice(_vm.customActiveIndex, 1);}}},[_vm._v("删除文字")])],1)],1):_vm._e(),(
            Object.keys(_vm.customModel).length > 0 && _vm.customModel.type === 'img'
          )?_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"filter-title"},[_vm._v("编辑图片")]),_c('edit-custom-img',{attrs:{"custom-model":_vm.customModel,"custom-active-index":_vm.customActiveIndex}}),_c('div',{staticClass:"all-center",staticStyle:{"margin-top":"15px"}},[_c('vs-button',{attrs:{"color":"#f56c6c"},on:{"click":function($event){_vm.customModel = {};
                _vm.customList.splice(_vm.customActiveIndex, 1);}}},[_vm._v("删除图片")])],1)],1):_vm._e()])]),_c('add-text-dialog',{attrs:{"show":_vm.customTextDialog},on:{"update:show":function($event){_vm.customTextDialog=$event},"confirm":_vm.confirmAddText}})],1),_c('page-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }