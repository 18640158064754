import request from "@/utils/request";
import baseUrl from "@/utils/url";

export const getAuthCodes = params => {
  return request("get", baseUrl.java + "/mask/getAuthCodes", params);
};

export const saveAuthCode = params => {
  return request("post", baseUrl.java + "/mask/saveAuthCode", params);
};

export const deleteAuthCode = params => {
  return request("post", baseUrl.java + "/mask/deleteAuthCode", params);
};

export const getAuthCode = params => {
  return request("get", baseUrl.java + "/mask/getAuthCode", params);
};

export const consumeCode = params => {
  return request("get", baseUrl.java + "/mask/consumeCode", params);
};
